import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

//Load Components
import { WorkWithUsCover } from "../components/shift/work-with-us/cover"
import { WorkWithUsBenefits } from "../components/shift/work-with-us/benefits"
import { WorkWithUsHiringProcess } from "../components/shift/work-with-us/hiring-process"
import { AboutRequest } from "../components/shift/about/request"
import { WorkWithUsPosition } from "../components/shift/work-with-us/position"

//Load Context
import { LangContext } from "../context/lang-context"

const WorkWithUsPage = () => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(GET_WORK_WITH_US_DETAILS)

  const block =
    lang === "EN"
      ? data?.wordPress?.workWithUs?.workWithUsPage
      : data?.wordPress?.workWithUs?.translation?.workWithUsPage ||
        data?.wordPress?.workWithUs?.workWithUsPage

  const request =
    lang === "EN"
      ? data?.wordPress?.aboutPage?.aboutPage
      : data?.wordPress?.aboutPage?.translation?.aboutPage ||
        data?.wordPress?.aboutPage?.aboutPage

  const positionData = data?.wordPress?.jobs?.nodes

  return (
    <Layout>
      <Seo title="Work With Us" />
      <WorkWithUsCover data={block.cover} />
      <WorkWithUsBenefits data={block.benefits} />
      <WorkWithUsPosition data={positionData} lang={lang} />
      <WorkWithUsHiringProcess data={block.hiringProcess} />
      <AboutRequest data={request.request} />
    </Layout>
  )
}

export default WorkWithUsPage

const GET_WORK_WITH_US_DETAILS = graphql`
  query WorkWithUs {
    wordPress {
      workWithUs: page(id: "work-with-us", idType: URI) {
        workWithUsPage {
          benefits {
            title
            label
            benefits {
              item
            }
          }
          cover {
            title
            text
            label
            image {
              sourceUrl
            }
          }
          general {
            applyUrl
          }
          hiringProcess {
            text
            image {
              sourceUrl
            }
          }
        }
        translation(language: ID) {
          workWithUsPage {
            benefits {
              title
              label
              benefits {
                item
              }
            }
            cover {
              title
              text
              label
              image {
                sourceUrl
              }
            }
            general {
              applyUrl
            }
            hiringProcess {
              text
              image {
                sourceUrl
              }
            }
          }
        }
      }
      aboutPage: page(id: "about", idType: URI) {
        aboutPage {
          request {
            title
            text
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            request {
              title
              text
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
          }
        }
      }
      jobs(first: 100, where: { language: EN }) {
        nodes {
          slug
          job {
            header {
              jobTitle
              departement {
                name
              }
            }
          }
          translation(language: ID) {
            job {
              header {
                jobTitle
                departement {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
