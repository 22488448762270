import React, { useState, useEffect, useContext } from "react"
import { Container, Section } from "../../anti/grid/grid"
import { Link } from "../../anti/link/link"

//Load Context
import { LangContext } from "../../../context/lang-context"
import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "../../anti/buttons/buttons"

export const WorkWithUsPosition = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const { lang } = useContext(LangContext)

  const block = data

  //load more function
  const postsPerPage = 8
  let arrayForHoldingPosts = []
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(8)

  const loopWithSlice = (start, end) => {
    const slicedPosts = block.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    // return function cleanup() {
    //   setPostsToShow([])
    // }
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
  }

  console.log(postsToShow)
  return (
    <Section forwardRef={trigger} className="sc-position bg-light">
      <Container>
        <h3 className="h3 mb-md-4">
          {lang === "EN" ? "Available Positions" : "Posisi yang Tersedia"}
        </h3>
        <div className="row">
          {postsToShow &&
            postsToShow.map((data, i) => {
              const dataLang = lang === "EN" ? data : data?.translation
              const position = dataLang?.job?.header
              return (
                <div className="col-md-3" key={i}>
                  <Link to={`/work-with-us/${data?.slug}`} className="card-job">
                    <div className="bg-white p-4">
                      <h4 className={`h4 mb-md-5 ${anim(1)}`}>
                        {position?.jobTitle}
                      </h4>
                      <h5 className={`h5 font-weight-normal ${anim(2)}`}>
                        {position?.departement?.name}
                      </h5>
                    </div>
                  </Link>
                </div>
              )
            })}
          {postsToShow.length === 0 && (
            <p className="mt-5 text-center w-100">
              {lang === "EN"
                ? "There is no available position at the moment."
                : "Saat ini tidak ada posisi yang Tersedia"}
            </p>
          )}
          {postsToShow.length != 0 && postsToShow.length != block.length && (
            <div className="d-flex justify-content-center w-100 mt-4">
              <Button variant="primary" onClick={handleShowMorePosts}>
                Load More
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}
