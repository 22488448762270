import React from "react"

import { Container, Section } from "../../anti/grid/grid"
import { useScrollAnim } from "src/components/hooks/hooks"

export const WorkWithUsBenefits = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data

  return (
    <Section forwardRef={trigger} className="work-with-us-benefits">
      <Container>
        <h6 className={`h6 ${anim(2)}`}>{block?.label}</h6>
        <h2 className={`h2 ${anim(3)}`}>{block?.title}</h2>
        <ul>
          {block?.benefits.map((data, i) => (
            <li key={i} className={`w-md-500px ${anim(4 + i)}`}>
              {data.item}
            </li>
          ))}
        </ul>
      </Container>
      {/* <div className="circle-graphic" /> */}
    </Section>
  )
}
